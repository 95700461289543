.benefit {
  font-family: var(--text-ff);
  color: var(--dark);
  padding: 110px 0;
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 100px;
  }
  &__list {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 40px;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 360px;
  }
  &__item-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    color: var(--white);
    border: 2px solid var(--dark);
    border-radius: 50em;
    background: var(--dark);
  }
  &__item-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 133%;
    margin-top: 20px;
  }
  &__item-desc {
    font-size: 16px;
    margin-top: 10px;
    line-height: 150%;
  }
}
