.footer {
  font-family: var(--text-ff);
  font-weight: 600;
  font-size: 14px;
  // background: linear-gradient(to right, #33455b, #58687f);
  background: linear-gradient(to left, var(--black), var(--black), #33455b);
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 50px;
  text-transform: uppercase;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__copyright {
  }
  &__author {
  }

  &__links {
  }
  &__link {
    transition: color 0.3s;
  }
  &__link:hover {
    color: var(--hover-link);
  }
  &__policy {
  }
}

.konmitin {
  transition: all 0.3s;
}
.konmitin:hover {
  color: var(--hover-link);
}
