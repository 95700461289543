@import "base/_reset";
@import "base/_variable";
@import "base/_fonts";
@import "base/_generalis";

@import "header";
@import "promo";
@import "about";
@import "rubber";
@import "benefit";
@import "cta";
@import "footer";
@import "adaptive";
@import "privacy";
@import "popup";


@keyframes pulsar-btn {
    from {
      background: url(/img/first-screen-btn.svg) center no-repeat;
      background-size: 100% 100%;
    }
  
    to {
      background: url(/img/first-screen-btn-no.svg) center no-repeat;
      background-size: 100% 100%;
    }
  }
