.cta {
  font-family: var(--text-ff);
  // background: linear-gradient(to right, #33455b, #58687f);
  background: linear-gradient(to left, var(--black), var(--black), #33455b);

  background-size: cover;
  padding-top: 60px;
  padding-bottom: 50px;
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__form {
    display: flex;
    flex-direction: column;
    color: var(--black);
    padding: 40px;
    background: var(--white);
    max-width: 550px;
    border-radius: 15px;
    border: 6px solid var(--dark);
  }
  &__form-title {
    font-size: 32px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  &__form-subtitle {
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 30px;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }
  &__input {
    width: 100%;
    font-size: 18px;
    padding: 20px 15px;
    background: var(--white);
    // border: 1px solid var(--dark);
    border-radius: 5px;
    box-shadow: 2px 6px 6px var(--dark);
    &._error {
      border: 1px solid red;
    }
    &_checkbox {
    }
  }
  &__form-button {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    //
    background: #0d6ca2;
    border-radius: 8px;
    border: none;
    height: 75px;
    font-size: 20px;
    line-height: 152%;
    color: var(--white);
    font-weight: 600;
  }
  &__policy {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    &._error {
      border: 1px solid red;
    }
  }
  &__right {
  }
  &__right-title {
    font-size: 48px;
    line-height: 130%;
    font-weight: 700;
    max-width: 580px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  &__photo {
    width: 600px;
    height: 350px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
