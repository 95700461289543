@media (max-width: 1440px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .rubber {
    &__button-block {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }
    &__desc {
      font-size: 18px;
      max-width: 740px;
    }
    &__button-subtitle {
      text-align: center;
      max-width: 300px;
    }
  }
}

@media (max-width: 1380px) {
  .rubber {
    &__container {
      flex-direction: column;
      &_right {
        flex-direction: column-reverse;
      }
    }
    &__title {
      font-size: 36px;
      width: auto;
      max-width: none;
      flex-grow: 0;
    }
    &__content {
      &_right {
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &__photos {
      justify-content: flex-start;
      &_right {
        justify-content: flex-end;
      }
    }
    &__button-block {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    &__button {
      font-size: 18px;
    }
  }
  .cta {
    &__container {
      //   flex-direction: column-reverse;
    }
    &__photo {
      width: 500px;
      height: 350px;
    }
  }
}

@media (max-width: 1320px) {
  .cta {
    &__container {
      align-items: center;
      flex-direction: column-reverse;
    }
    &__photo {
      width: 500px;
      height: 350px;
    }
  }
  .about {
    &__container {
      flex-direction: column-reverse;
      gap: 20px;
      &_right {
        flex-direction: column;
      }
    }
    &__photo {
      width: 600px;
      height: 500px;
    }
    &__list {
      margin: 0;
    }
  }
  .nav {
    background: var(--dark);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    visibility: visible;
    opacity: 0;
    position: fixed;
    z-index: 100;
    transition: all 0.3s;
    transform: translateX(-100%);
    &.isActive {
      opacity: 1;
      transform: translateX(0%);
    }
    &__list {
      padding-top: 40px;
      padding-left: 35px;
      flex-direction: column;
    }
  }
  .header {
    &__info {
      align-items: center;
    }
    &__info-bottom {
      align-items: center;
    }
    &__info-times {
      font-size: 14px;
    }
    &__info-item {
      font-size: 18px;
    }
    &__main-container {
      gap: 50px;
    }
    &__main-content {
      gap: 15px;
    }
    &__main-title {
      font-size: 48px;
    }
    &__main-description {
      font-size: 18px;
      line-height: 145%;
    }
    &__main-button {
      font-size: 18px;
    }
    &__main-subtitle {
      font-size: 14px;
    }
    &__main-map {
      max-width: 350px;
      max-height: 350px;
    }
    &__main-map iframe {
    }
  }
  .burger {
    display: flex;
    visibility: visible;
  }
}

@media (max-width: 880px) {
  .rubber {
    padding-top: 80px;
    padding-bottom: 80px;
    &__container {
      align-items: center;
    }
    &__title {
      padding-left: 15px;
    }
    &__content {
      &_right {
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &__desc {
      font-size: 18px;
      max-width: 640px;
    }
    &__photos {
      align-items: center;
      justify-content: center;
      &_right {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    &__photo {
      width: 300px;
      height: 300px;
    }
    &__button-block {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    &__button {
      font-size: 18px;
    }
    &__button-subtitle {
      font-size: 24px;
    }
  }
  .header {
    &__main-container {
      flex-direction: column;
    }
    &__main-map {
      display: none;
    }
  }
  .benefit {
    &__list {
      flex-direction: column;
    }
  }
}

@media (max-width: 720px) {
  .rubber {
    padding-top: 80px;
    padding-bottom: 80px;
    &__container {
      align-items: center;
      &_right {
        align-items: center;
      }
    }
    &__title {
      text-align: center;
      padding-left: 0;
      font-size: 32px;
      max-width: 350px;
    }
    &__content {
      flex-direction: column-reverse;
      gap: 40px;
      &_right {
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &__desc {
      font-size: 18px;
      max-width: 350px;
    }
    &__photos {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_right {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    &__photo {
      width: 350px;
      height: 350px;
    }
    &__button-block {
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      &_left {
        flex-direction: column;
      }
    }
    &__button-subtitle {
      text-align: center;
    }
  }
  .cta {
    &__container {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }
    &__form {
      max-width: 450px;
    }
    &__input {
      font-size: 16px;
    }
    &__form-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }
    &__form-subtitle {
      font-size: 14px;
      text-align: center;
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
    }
    &__right-title {
      font-size: 36px;
    }
    &__photo {
      width: 400px;
      height: 350px;
    }
  }
  .about {
    &__container {
      padding-bottom: 60px;
      padding-top: 60px;
      flex-direction: column-reverse;
      gap: 20px;
      &_right {
        flex-direction: column;
      }
    }
    &__photo {
      width: 480px;
      height: 400px;
    }
    &__title {
      font-size: 36px;
    }
    &__item {
      font-size: 16px;
      max-width: 480px;
    }
  }
  .header {
    &__info {
      position: fixed;
      display: flex;
      visibility: hidden;
      bottom: 40px;
      left: 35px;
      z-index: 110;
      align-items: flex-start;
      transition: all 0.2s;
      opacity: 0;
    }

    &__info.isActive {
      visibility: visible;
      opacity: 1;
    }
    &__info-bottom {
      align-items: flex-start;
    }
    &__info-times {
      font-size: 12px;
    }
    &__info-item {
      font-size: 16px;
    }
    &__main-container {
      gap: 50px;
    }
    &__main-content {
      gap: 15px;
    }
    &__main {
      height: calc(100vh - 80px);
    }
    &__main-title {
      font-size: 36px;
    }
    &__main-description {
      font-size: 16px;
      line-height: 140%;
    }
    &__main-cta {
      align-items: flex-start;
      flex-direction: column;
      max-width: 280px;
    }
    &__main-button {
      display: flex;
      justify-content: center;
      font-size: 18px;
      width: 100%;
    }
    &__main-subtitle {
      text-align: center;
      font-size: 14px;
      width: 100%;
      max-width: none;
    }
  }
  .footer {
    &__container {
      flex-direction: column-reverse;
      gap: 30px;
    }
  }
}

@media (max-width: 500px) {
  .rubber {
    &__desc {
      font-size: 18px;
      max-width: none;
    }
    &__photo {
      width: 100%;
      height: 350px;
    }
  }

  .cta {
    &__container {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }
    &__form {
      max-width: 350px;
    }
    &__input {
      font-size: 14px;
    }
    &__form-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }
    &__form-subtitle {
      font-size: 14px;
      text-align: center;
    }
    &__form-button {
      font-size: 16px;
      height: 65px;
    }
    &__right {
      text-align: center;
      max-width: 350px;
    }
    &__right-title {
      font-size: 36px;
    }
    &__photo {
      width: 350px;
      height: 250px;
    }
  }

  .about {
    &__container {
      padding-bottom: 20px;
      padding-top: 20px;
      flex-direction: column-reverse;
      gap: 20px;
      &_right {
        flex-direction: column;
      }
    }
    &__photo {
      width: 100%;
      height: 400px;
    }
    &__title {
      margin-bottom: 10px;
      font-size: 36px;
    }
    &__item {
      font-size: 16px;
      max-width: none;
    }
  }
}

@media (max-width: 400px) {
  .rubber {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .cta {
    &__form {
      max-width: 320px;
    }
    &__right {
      text-align: center;
      max-width: 320px;
    }
    &__right-title {
      font-size: 28px;
    }
    &__photo {
      width: 320px;
      height: 250px;
    }
  }
}
