.documents-popup {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    opacity: 0.8;
    z-index: 10;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 100%;
    max-height: 800px;
    padding: 30px 45px;
    position: relative;
    z-index: 1000;
    border-radius: 20px;
  }
  &__title {
    font-size: 24px;
    font-family: var(--title-ff);
    font-weight: 500;
    margin-bottom: 50px;
  }
  &__list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__item {
    max-height: fit-content;
  }

  &__item-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 16px;
    font-family: var(--title-ff);
    font-weight: 400;
    line-height: 120%;
    max-width: 200px;
    overflow: hidden;
  }
  &__item-img-block {
    border-radius: 15px;
    max-height: 250px;
    max-width: 100%;
    overflow: hidden;
  }
  &__item-img {
    
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &__item-name {
  }
  &__close {
    color: var(--white);
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--white);
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.document-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    opacity: 0.8;
    z-index: 10;
  }
  &__title {
    font-size: 18px;
    font-family: var(--title-ff);
    text-align: center;
    font-weight: 500;
    position: relative;
    z-index: 1000;
    margin-top: 40px;
  }
  &__container {
    height: 100%;
    max-width: 800px;
    max-height: 800px;
    padding: 25px 45px;
    position: relative;
    z-index: 1000;
  }
  &__item-img {
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
  }
  &__close {
    color: var(--white);
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    top: 3%;
    right: 0;
    width: 30px;
    height: 30px;
    &:before,
    &:after {
      content: "";

      position: absolute;
      width: 30px;
      height: 2px;
      background: var(--white);
      // border-radius: 15px;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 660px) {
  .documents-popup {
    &__close {
      margin-right: 5px;
    }
  }
  .document-popup {
    &__container {
      max-width: max-content;
      max-height: 700px;
    }

    &__close {
      top: 98%;
      right: 50%;
    }
  }
}

@media (max-width: 480px) {
  .documents-popup {
    &__close {
      margin-top: 30px;
      margin-right: 5px;
    }
    &__list  {
      overflow-x: scroll;
    }
  }
  .document-popup {
    height: 100%;
    &__container {
      margin-top: 20px;
      max-width: 98%;
      padding: 0;
    }

    &__close {
      top: 90%;
      right: 50%;
    }
    &__item-img {
      width: 100%;
      height: auto;
    }
  }
}
