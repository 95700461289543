.about {
  //   height: 100vh;
  font-family: var(--text-ff);
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  &__photo {
    display: flex;
    width: 620px;
    height: 600px;
    border-radius: 15px;
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__content {
  }
  &__title {
    color: var(--black);
    font-size: 36px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &__list {
    margin-bottom: 50px;
  }
  &__item {
    color: var(--dark);
    max-width: 550px;
    font-weight: 500;
    font-size: 16px;
    line-height: 152%;
    & a {
      color: var(--hover-link);
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 30px;
  }
  &__button {
    font-family: inherit;
    font-size: 24px;
    font-weight: 500;
    color: var(--white);
    background: var(--black);
    border-radius: 75px;
    padding: 25px 50px;
    box-shadow: 0px 0px 25px var(--dark);
    transform: scale(0.98);
    transition: all 0.3s;
  }
  &__button:hover {
    transform: scale(1);
  }
}
