.privacy {
  color: var(--dark);
  margin: 0 25px 0 25px;
  &__top {
    margin: 10px 0 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__top a {
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    margin-left: 5px;
    color: var(--dark);
  }
  &__top a:hover {
    border-bottom: 0;
    margin-bottom: 1px;
  }
  &__title {
    text-transform: uppercase !important;
    font-weight: 600 !important;
    &_h3 {
      text-transform: uppercase !important;
      font-weight: 600 !important;
    }
  }
  &__ul p {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul li {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul-in-ul p {
    font-size: 14px;
    margin: 5px 0 5px 15px;
    line-height: 20px;
  }
  &__ul-in-ul li {
    font-size: 14px;
    margin: 5px 0 5px 25px;
    line-height: 20px;
  }
}
