html {
  scroll-behavior: smooth;
}

body {
  color: var(--white);
  &.isHidden {
    overflow: hidden;
  }
}

a {
  color: var(--white);
}

.title {
  font-family: var(--title-ff);
  &_h2 {
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.burger {
  display: none;
  visibility: hidden;
  width: 30px;
  height: 25px;
  position: relative;
  z-index: 110;
  &::before,
  &::after,
  &__span {
    content: "";
    width: 100%;
    position: absolute;
    height: 2px;
    background: var(--white);
    transition: all 0.5s;
  }
  &::before {
    top: 0;
    transform: translate();
  }
  &::after {
    bottom: 0;
  }
  &__span {
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.5s;
  }
  &.isActive::after {
    bottom: 50%;
    transform: translate(0, 50%) rotateZ(45deg);
  }
  &.isActive::before {
    top: 50%;
    transform: translate(0, -50%) rotateZ(-45deg);
  }
  &.isActive &__span {
    transform: translate(0, -50%) rotate(180deg) scaleX(0);
  }
}

.thanks-popup {
  visibility: hidden;
  position: fixed;
  z-index: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  &.isActive {
    visibility: visible;
    transform: translate(-50%, -50%);
    z-index: 200;
    opacity: 1;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.3;
    top: 50%;
    z-index: 300;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__box {
    padding: 50px;
    background: #fff;
    border-radius: 10px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 450;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
  &__title {
    font-size: 36px;
    color: var(--black);
  }
  &__description {
    font-size: 20px;
    color: var(--black);
  }
  &__close {
    position: absolute;
    margin: 15px;
    background: #fff;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
  }
  &__close::after,
  &__close::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 2px;
    background: var(--black);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__close::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &__close::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.to_top {
  display: flex;
  visibility: visible;
  opacity: 1;
  position: fixed;
  right: 5vw;
  bottom: 5vh;
  z-index: 1000;
  padding: 0;
  width: 80px;
  height: 80px;
  border-radius: 90px;
  transition: all 0.3s;
  &_hide {
    opacity: 0;
    visibility: hidden;
  }
}
