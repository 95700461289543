@mixin font-face($name, $file, $weight, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url("../fonts/#{$file}.woff2") format("woff2"),
      url("../fonts/#{$file}.woff") format("woff");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}
