:root {
  --hover-link: #2895f4;
  --white: #ffffff;
  --dark: #505f76;
  --black: #222b34;
  --text-size: 40px;
  --index: calc(1vw + 1vh);
  --title-ff: "Montserrat", sans-serif;
  --text-ff: "Montserrat", sans-serif;
}
