.rubber {
  font-family: var(--text-ff);
  width: 100%;
  background: linear-gradient(to right, #33455b, #58687f);
  background-size: cover;
  //   height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  &__container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    // width: 100%;
  }
  &__title-block {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    // height: 100%;
    &_left {
      align-items: start;
      & .rubber__button-subtitle {
        text-align: left;
      }
    }
  }
  &__title {
    display: flex;
    text-transform: initial;
    flex-grow: 1;
    width: 100%;
    font-size: 36px;
    line-height: 60px;
    font-weight: 700;
    max-width: 400px;
    text-align: left;
    &_right {
      text-align: right;
      justify-content: flex-end;
    }
  }
  &__button-block {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__button-subtitle {
    font-size: 24px;
    font-weight: 600;
    text-align: right;
    line-height: 120%;
  }
  &__button {
    display: flex;
    flex-shrink: 0;
    max-width: 300px;
    font-family: inherit;
    font-size: 24px;
    font-weight: 500;
    color: var(--white);
    background: var(--black);
    border-radius: 75px;
    padding: 25px 50px;
    box-shadow: 0px 0px 25px var(--black);
    transform: scale(0.98);
    transition: all 0.3s;
  }
  &__button:hover {
    transform: scale(1);
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__desc {
    // text-align: justify;
    // text-indent: 40px;
    font-size: 20px;
    line-height: 160%;
    max-width: 740px;
    font-weight: 500;
    margin-bottom: 40px;
    &_light {
      font-weight: 300;
    }
    &_link {
      font-weight: normal;
      transition: all 0.3s;
    }
    &_link:hover {
      color: var(--hover-link);
    }
    &_right {
      text-indent: 0px;
    }
  }

  &__photos {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 40px;
  }
  &__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    overflow: hidden;
    border-radius: 25px;
    transform: scale(1);
    transition: all 0.3s;
  }
  &__photo:hover {
    transform: scale(1.02);
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
