.header {
  font-size: 24px;
  font-family: var(--text-ff);
  height: 100vh;
  &__top {
    display: flex;
    align-items: center;
    // background: var(--black);
    background: linear-gradient(to right, var(--black), var(--black), #33455b);
    // background: linear-gradient(to right, #33455b, #58687f);
    min-height: 80px;
    padding: 25px 0;
  }
  &__top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__logo {
    display: flex;
    max-height: 80px;
    max-width: 180px;
  }

  &__logo img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  &__info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 15px;
  }
  &__info-top {
    display: flex;
    gap: 10px;
  }
  &__info-bottom {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
  }
  &__info-times {
    font-size: 16px;
    transition: color 0.3s;
    &_link:hover {
      color: var(--hover-link);
    }
  }
  &__info-item {
    display: flex;
    font-size: 20px;
    width: 100%;
    height: 100%;
    transition: color 0.3s;
  }
  &__info-item:hover {
    color: var(--hover-link);
  }
  &__main {
    width: 100%;
    height: calc(100vh - 120px);
    // background: url("../img/header-bg.png") no-repeat;
    background: var(--black);
    background: linear-gradient(to right, var(--black), var(--black), #33455b);
  }
  &__main-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__main-left {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
  }
  &__main-content {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 740px;
  }
  &__main-title {
    // text-transform: uppercase;
    font-size: 64px;
    line-height: 60px;
    font-weight: 700;
  }
  &__main-description {
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
  }
  &__main-cta {
    display: flex;
    align-items: center;
    font-family: inherit;
    gap: 15px;
  }
  &__main-button {
    display: flex;
    flex-shrink: 0;
    max-width: 300px;
    font-family: inherit;
    font-size: 24px;
    font-weight: 500;
    color: var(--black);
    background: var(--white);
    border-radius: 75px;
    padding: 25px 50px;
    box-shadow: 0px 0px 25px var(--white);
    transform: scale(0.98);
    transition: all 0.3s;
  }
  &__main-button:hover {
    transform: scale(1);
  }
  &__main-subtitle {
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    max-width: 280px;
  }
  &__main-map {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 500px;
    max-height: 500px;
    border-radius: 30px;
    overflow: hidden;
  }
  &__main-map iframe {
    width: 100%;
    height: 100%;
  }

  &__main-map span {
    display: flex;
    height: 120px;
    width: 120px;

    -webkit-animation-name: pulsar-btn;
    animation-name: pulsar-btn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;

    
  }
}

.nav {
  &__list {
    display: flex;
    gap: 30px;
  }
  &__link {
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    // width: 100%;
    // height: 100%;
    transition: color 0.3s;
  }
  &__link:hover {
    color: var(--hover-link);
  }
}
