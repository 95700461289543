.promo {
  font-family: var(--text-ff);
  color: var(--black);
  padding: 110px 0;
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__title {
    color: var(--dark);
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 80px;
  }
  &__list {
    display: flex;
    // margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 40px;
  }
  &__item {
    display: flex;
    gap: 10px;
    max-width: 630px;
    max-height: 250px;
    box-shadow: 5px 5px 25px var(--dark);
    overflow: hidden;
    border-radius: 15px;
    &-left {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      &-img {
        width: 100%;
        left: 0;
        top: 0;
        // object-fit: fill;
      }
    }
    &-right {
      padding: 15px 25px 15px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-top {
      }

      &-title {
        color: var(--dark);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
      }
      &-desc {
        color: var(--dark);
        font-size: 18px;
        font-weight: 500;
        margin-top: 10px;
        line-height: 140%;
        max-width: 320px;
      }

      &-button {
        display: flex;
        justify-content: center;
        background: var(--dark);
        padding: 15px;
        // border: 1px solid var(--dark);
        border-radius: 10px;
        transition: all 0.3s;
        box-shadow: 0px 0px 10px var(--dark);
        transform: scale(0.98);
        font-size: 18px;
      }

      &-button:hover {
        background: var(--white);
        color: var(--dark);
        transform: scale(1);
        // border-color: var(--white);
      }
    }
  }
  &__item-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    color: var(--white);
    border: 2px solid var(--dark);
    border-radius: 50em;
    background: var(--dark);
  }
}

@media(max-width: 700px) {
    .promo {
        &__item {
            flex-direction: column;
            max-width: 330px;
            max-height: 590px;
            width: 100%;
            &-left {
                justify-content: center;
                width: 100%;
                height: 60%;
                &-img {
                    width: 100%;
                    object-fit: contain;
                }
            }
            &-right {
                padding: 0 15px 15px 15px;
                flex-direction: column;
                flex-grow: 1;
            }
        }
    }
}
 